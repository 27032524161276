<script setup lang="ts">
/**
 * 'fluent:javascript-16-regular',
 * 'akar-icons:typescript-fill'
  'akar-icons:php-fill',
  'mdi:language-ruby',
  'bx:bxl-python',
  'simple-icons:go',
 */
const icons: { name: string, icon: string }[] = [
  { name: 'JavaScript', icon: 'fluent:javascript-16-regular' },
  { name: 'TypeScript', icon: 'akar-icons:typescript-fill' },
  { name: 'PHP', icon: 'akar-icons:php-fill' },
  { name: 'Ruby', icon: 'mdi:language-ruby' },
  { name: 'Python', icon: 'bx:bxl-python' },
  { name: 'Go', icon: 'simple-icons:go' },
]

// const email = ref('')
</script>

<template>
  <div id="home" class="background-squares relative">
    <div class="absolute h-full w-full bg-[rgba(255,255,255,0.5)] dark:bg-[rgba(0,0,0,0.75)] " />
    <section class="relative flex flex-col items-center py-16 justify-center z-10 gap-x-2 gap-y-4 flex-wrap min-h-[calc(100vh-6rem)] container">
      <div class="flex flex-col justify-center gap-y-6 text-center ">
        <p class="text-2xl sm:text-3xl  md:text-4xl lg:text-5xl xl:text-6xl font-bold max-w-2xl">
          Focus on the <span class="font-black underline text-green-500">20%</span> your customers need, not the <span class="font-extrabold line-through text-red-500">80%</span> they don't
        </p>
        <h1 class="text-lg text-muted-foreground max-w-lg mx-auto text-center">
          <b>Get insights</b> on what your customers really care about, develop the <b>right features</b> and <b>grow</b> your business.
        </h1>
        <div class="flex flex-wrap lg:flex-nowrap mx-auto justify-center gap-x-2 mt-8">
          <!-- <Input v-model="email" placeholder="Your Email address" class="flex-1" /> -->
          <LandingStartButton size="lg" />
          <!-- <LandingWaitlistForm v-model:email="email" /> -->
        </div>
      </div>
      <div class="bg-muted/60 py-2 px-4 rounded-md text-center mt-10">
        <p class="text-sm">
          Integrate with
        </p>
        <div class="flex items-center flex-wrap gap-4 mt-2">
          <template v-for="icon in icons" :key="icon.name">
            <div>
              <iconify-icon :title="icon.name" :icon="icon.icon" class="text-4xl dark:text-slate-50" />
              <span class="sr-only">{{ icon.name }}</span>
            </div>
          </template>
        </div>
        <p class="text-sm">
          And many more...
        </p>
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
