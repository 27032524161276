<script lang="ts" setup>

</script>

<template>
  <header class="sticky top-0 z-20  bg-background ">
    <div class=" flex h-24 items-center container gap-4px-4 md:px-6 z-10">
      <nav
        class="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6"
      >
        <NuxtLink to="/" class="flex items-center gap-2 text-lg font-semibold md:text-base">
          <div class="w-10">
            <NuxtImg alt="Featrack.io logo" width="40px" height="40px" sizes="40px" src="/logo_pink.svg" />
          </div>
          <span>Featrack.io</span>
        </NuxtLink>
        <NuxtLink
          to="#benefits"
          class="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap"
        >
          Why Featrack ?
        </NuxtLink>
        <NuxtLink to="#integration" class="text-muted-foreground transition-colors hover:text-foreground">
          Integration
        </NuxtLink>
        <NuxtLink to="#features" class="text-muted-foreground transition-colors hover:text-foreground">
          Features
        </NuxtLink>
        <NuxtLink to="#faq" class="text-muted-foreground transition-colors hover:text-foreground">
          FAQ
        </NuxtLink>
        <!-- <a href="#" class="text-muted-foreground transition-colors hover:text-foreground">
          Analytics
        </a> -->
      </nav>
      <Sheet>
        <SheetTrigger as-child>
          <Button variant="outline" size="icon" class="shrink-0 md:hidden">
            <iconify-icon icon="lucide:menu" class="text-lg" />
            <span class="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <nav class="grid gap-6 text-lg font-medium container">
            <a href="#" class="flex items-center gap-2 text-lg font-semibold w-10">
              <NuxtImg src="/logo_pink.svg" />

              <span class="sr-only">FeaTrack</span>
            </a>
            <NuxtLink to="#benefits" class="text-muted-foreground hover:text-foreground whitespace-nowrap">
              Why Featrack ?
            </NuxtLink>
            <NuxtLink to="#integration" class="text-muted-foreground hover:text-foreground">
              Integration
            </NuxtLink>
            <NuxtLink to="#features" class="text-muted-foreground hover:text-foreground">
              Features
            </NuxtLink>
            <NuxtLink to="#faq" class="text-muted-foreground hover:text-foreground">
              FAQ
            </NuxtLink>
            <!-- <a href="#" class="text-muted-foreground hover:text-foreground">
              Analytics
            </a> -->
          </nav>
        </SheetContent>
      </Sheet>
      <div class="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
        <form class="ml-auto flex-1 sm:flex-initial">
          <!-- <div class="relative">
            <Search class="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input type="search" placeholder="Search products..." class="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]" />
          </div> -->
        </form>
        <ClientOnly>
          <AppThemeSwitcher />
        </ClientOnly>
        <ClientOnly>
          <LandingStartButton />
          <!-- <LandingWaitlistForm v-model:email="email" size="default" /> -->
        </ClientOnly>
      </div>
    </div>
    <AppAnnoncementsBanner />
  </header>
</template>
