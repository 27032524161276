<script lang="ts" setup>
const email = ref('')
</script>

<template>
  <section id="get-started" class="py-24 flex justify-center items-center dark bg-background text-foreground background-squares relative">
    <div class="absolute h-full w-full bg-[rgba(255,255,255,0.5)] dark:bg-[rgba(0,0,0,0.65)] " />
    <div class="container z-10 flex flex-col">
      <h2 class="text-center text-4xl font-extrabold  max-w-3xl mx-auto">
        Get in touch, subscribe to our <span class="font-black underline">Newsletter</span>.
      </h2>
      <p class="text-center max-w-2xl mx-auto mt-4">
        Sign up to get the latest updates and news about Featrack.io.
      </p>

      <div class="flex flex-wrap lg:flex-nowrap mx-auto justify-center gap-x-2 mt-8">
        <Input v-model="email" placeholder="Your Email address" class="flex-1" />
        <LandingWaitlistNewsletterForm v-model:email="email" />
      </div>

      <p class="text-center text-xs text-muted-foreground mt-2">
        By signing up, you agree to our <NuxtLink to="/terms" class="text-primary">
          Terms of Service
        </NuxtLink> and <NuxtLink to="/privacy" class="text-primary">
          Privacy Policy
        </NuxtLink>.
      </p>
    </div>
  </section>
</template>
