<script lang="ts" setup>
import { cp } from 'node:fs'
import { toast } from 'vue-sonner'
import { z } from 'zod'
import type { Config } from '~/components/ui/auto-form'

defineProps<{
  size?: 'sm' | 'default' | 'lg' | 'icon'
}>()

const email = defineModel('email', { type: String })

const sent = ref(false)

const schema = computed(() => z.object({
  email: z.string().email().default(email.value || ''),
  name: z.string().min(1).max(255),
})
)
const fieldsConfig = computed<Config<z.infer<typeof schema.value>>>(() => ({
  email: {
    label: 'Email',
    inputProps: {
      placeholder: 'Enter your email address',
    },
  },
  name: {
    label: 'Name',
    inputProps: {
      placeholder: 'Enter your name',
    },
  },
}))

const { $client } = useNuxtApp()
async function sendForm(data: z.infer<typeof schema.value>) {
  const response = await $client.external.sendNewsletterSubscriptionRequest.mutate({
    ...data,
    requested_at: new Date(),
  })

  if (!response?.success) {
    toast.error('Failed to send request, please try again later')
    return
  }

  email.value = ''
  sent.value = true

  setTimeout(() => {
    sent.value = false
  }, 5000)
}
</script>

<template>
  <Dialog>
    <DialogTrigger>
      <slot name="trigger">
        <AppButton :size="size || 'lg'" icon="lucide:move-up-right" class="flex-0">
          Subscribe to our newsletter
        </AppButton>
      </slot>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Subscribe to our newsletter
        </DialogTitle>
        <DialogDescription>
          Get the latest updates and news about our product
        </DialogDescription>
      </DialogHeader>

      <AutoForm v-if="!sent" :schema="schema" :field-config="fieldsConfig" class="space-y-2" @submit="sendForm">
        <AppButton variant="default" type="submit" icon="lucide:sparkles" label="Subscribe now" />
      </AutoForm>

      <div v-else>
        <Alert>
          <AlertTitle class="text-green-500 font-bold">
            <iconify-icon icon="lucide:thumbs-up" />
            <span>
              You have successfully subscribed to our newsletter
            </span>
          </AlertTitle>
          <AlertDescription>
            You will receive the latest updates and news about our product
          </AlertDescription>
        </Alert>
      </div>
      <DialogFooter>
        <a href="https://docs.tracecom.io" target="_blank">
          <div class="absolute bottom-0 right-0 flex py-2 px-2 bg-purple-500 dark:bg-yellow-500 rounded-lg">
            <span class="text-xs text-white">Powered by <span class="underline font-black text-md">Tracecom.io</span></span>
          </div>
        </a>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
