<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue'
import {
  ProgressIndicator,
  ProgressRoot,
  type ProgressRootProps,
} from 'radix-vue'
import { cn } from '@/lib/utils'

type ProgressVariants = 'primary' | 'secondary' | 'destructive' | 'success' | 'warning'

const props = withDefaults(
  defineProps<ProgressRootProps & { class?: HTMLAttributes['class'], variant?: ProgressVariants }>(),
  {
    modelValue: 0,
    variant: 'primary',
  },
)

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const bg = computed(() => {
  switch (props.variant) {
    case 'primary':
      return 'bg-primary'
    case 'secondary':
      return 'bg-secondary'
    case 'destructive':
      return 'bg-destructive'
    case 'success':
      return 'bg-green-500'
    case 'warning':
      return 'bg-amber-500'
    default:
      return 'bg-primary'
  }
})
</script>

<template>
  <ProgressRoot
    v-bind="delegatedProps"
    :class="
      cn(
        'relative h-4 w-full overflow-hidden rounded-full bg-secondary',
        props.class,
      )
    "
  >
    <ProgressIndicator
      class="h-full w-full flex-1 transition-all"
      :class="bg"
      :style="`transform: translateX(-${100 - (props.modelValue ?? 0)}%);`"
    />
  </ProgressRoot>
</template>
