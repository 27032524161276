<script lang="ts" setup>

</script>

<template>
  <footer class="dark bg-background text-foreground">
    <div class="container max-w-3xl flex gap-4 py-16">
      <div class="flex flex-col gap-4 w-1/3">
        <div class="w-16">
          <NuxtImg alt="Featrack.io logo" loading="lazy" width="64px" height="64px" sizes="64px" src="/logo_pink.svg" />
        </div>
        <p class="text-xs text-muted-foreground">
          Featrack.io is a SaaS tool that helps businesses track and monitor feature usage by their customers. <br>
          Created with ❤️ by Jeepay Jipex.
        </p>
      </div>
      <div class="flex flex-col gap-4 w-1/3">
        <a href="#benefits" class="text-lg font-bold">Why Featrack.io ?</a>
        <a href="#integration" class="text-lg font-bold">Integration</a>
        <a href="#features" class="text-lg font-bold">Features</a>
        <a href="#faq" class="text-lg font-bold">FAQ</a>
      </div>
      <div class="flex flex-col gap-4 w-1/3">
        <a href="/terms" class="text-lg font-bold">Terms</a>
        <a href="/privacy" class="text-lg font-bold">Privacy</a>
      </div>
    </div>
    <div class="bg-muted flex justify-between h-16 items-center">
      <div class="container max-w-3xl flex justify-between items-center gap-4">
        <p class="text-xs text-muted-foreground">
          © {{ (new Date()).getFullYear() }} Featrack.io. All rights reserved.
        </p>
        <div class="flex gap-4">
          <a href="https://x.com/jipexx_jean" class="text-lg text-muted-foreground transition-all hover:text-primary">
            <iconify-icon icon="lucide:twitter" />
            <span class="sr-only">My profile on X</span>
          </a>
          <a href="https://github.com/JeepayJipex" class="text-lg text-muted-foreground transition-all hover:text-primary">
            <iconify-icon icon="lucide:github" />
            <span class="sr-only">My profile on GitHub</span>
          </a>
          <!--           <a href="#" class="text-lg text-muted-foreground transition-all hover:text-primary">
            <iconify-icon icon="lucide:instagram" />
            <span class="sr-only">My profile on Instagram</span>
          </a> -->
        </div>
      </div>
    </div>
  </footer>
</template>
