<script lang="ts" setup>
</script>

<template>
  <section id="benefits" class="min-h-[90vh] py-16 flex justify-center items-center bg-background">
    <div class="container flex flex-col">
      <h2 class="text-center text-4xl font-extrabold  max-w-3xl mx-auto">
        Tired of developing <span class="font-black underline">cluttered businesses</span> with <span class="font-black underline">low ROI</span> ?
      </h2>

      <div class="flex w-full gap-x-16 gap-y-16 lg:gap-x-24 xl:gap-x-32 justify-center flex-wrap my-32">
        <div class="flex flex-col gap-y-4">
          <h3 class="font-bold text-2xl">
            <iconify-icon icon="lucide:thumbs-down" class="text-destructive" />
            Without Featrack integration
          </h3>
          <div class="mt-6">
            <div class="flex flex-col gap-y-2 mb-4">
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-destructive/30 flex place-items-center place-content-center rounded-sm border border-destructive/20">
                  <iconify-icon icon="lucide:x" class="text-destructive" />
                </div>
                <span>  No visibility into feature usage.</span>
              </div>

              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-destructive/30 flex place-items-center place-content-center rounded-sm border border-destructive/20">
                  <iconify-icon icon="lucide:x" class="text-destructive" />
                </div>
                <span>Missed upsell opportunities.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-destructive/30 flex place-items-center place-content-center rounded-sm border border-destructive/20">
                  <iconify-icon icon="lucide:x" class="text-destructive" />
                </div>
                <span>Unexpected overuse and hidden costs.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-destructive/30 flex place-items-center place-content-center rounded-sm border border-destructive/20">
                  <iconify-icon icon="lucide:x" class="text-destructive" />
                </div>
                <span>Manual, time-consuming tracking.</span>
              </div>

              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-destructive/30 flex place-items-center place-content-center rounded-sm border border-destructive/20">
                  <iconify-icon icon="lucide:x" class="text-destructive" />
                </div>
                <span>Struggles with personalized customer support.</span>
              </div>
            </div>
          </div>
          <Separator />
          <div class="flex gap-x-2 mt-4">
            <span class="font-bold text-xs">Customer satisfaction :</span>

            <div class="max-w-sm mx-auto flex-1">
              <AppProgress variant="destructive" :model-value="15" />
              <div class="flex items-center gap-x-2">
                <iconify-icon icon="lucide:frown" class="text-destructive" />
                <span class="text-sm text-destructive">15%</span>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-y-4">
          <h3 class="font-bold text-2xl">
            <iconify-icon icon="lucide:thumbs-up" class="text-green-500" />
            With Featrack integration
          </h3>
          <div class="mt-6">
            <div class="flex flex-col gap-y-2 mb-4">
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-green-500/30 flex place-items-center place-content-center rounded-sm border border-green-500/20">
                  <iconify-icon icon="lucide:check" class="text-green-500" />
                </div>
                <span>Real-time insights into feature usage.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-green-500/30 flex place-items-center place-content-center rounded-sm border border-green-500/20">
                  <iconify-icon icon="lucide:check" class="text-green-500" />
                </div>
                <span>Proactively offer upgrades when limits are near.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-green-500/30 flex place-items-center place-content-center rounded-sm border border-green-500/20">
                  <iconify-icon icon="lucide:check" class="text-green-500" />
                </div>
                <span>Set usage limits to avoid costly surprises.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-green-500/30 flex place-items-center place-content-center rounded-sm border border-green-500/20">
                  <iconify-icon icon="lucide:check" class="text-green-500" />
                </div>
                <span>Tracking set up in minutes, not hours.</span>
              </div>
              <div class="flex items-center gap-x-1">
                <div class="size-6 bg-green-500/30 flex place-items-center place-content-center rounded-sm border border-green-500/20">
                  <iconify-icon icon="lucide:check" class="text-green-500" />
                </div>
                <span>Boost customer satisfaction and retention with usage-based insights.</span>
              </div>
            </div>
          </div>
          <Separator />
          <div class="flex gap-x-2 mt-4">
            <span class="font-bold text-xs">Customer satisfaction :</span>

            <div class="max-w-sm mx-auto flex-1">
              <AppProgress variant="success" :model-value="92" />
              <div class="flex items-center justify-end gap-x-2">
                <iconify-icon icon="lucide:smile" class="text-green-500" />
                <span class="text-sm text-green-500"> 92%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
