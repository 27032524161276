<script lang="ts" setup>
import type { ButtonVariants } from '../ui/button'

withDefaults(defineProps<{
  size?: ButtonVariants['size']
}>(), {
  size: 'default'
})

const { status } = useAuth()

const buttonProps = computed(() => {
  if (status.value === 'authenticated') {
    return {
      to: '/dashboard',
      icon: 'lucide:layout-dashboard',
      text: 'To your dashboard',
    }
  }

  return {
    to: '/auth/signin',
    icon: 'lucide:rocket',
    text: 'Try it now for free',
  }
})
</script>

<template>
  <NuxtLink :to="buttonProps.to">
    <AppButton :size="size" :icon="buttonProps.icon">
      {{ buttonProps.text }}
    </AppButton>
  </NuxtLink>
</template>
