<script lang="ts" setup>
definePageMeta({
  auth: { unauthenticatedOnly: true, navigateAuthenticatedTo: '/dashboard' },
  title: 'Tired of focusing on what your customers don\'t care about ? | Featrack',
  uncheckOnboarding: true,
  unPaidAccess: true,
})

useHead(
  {
    title: 'Tired of focusing on what your customers don\'t care about ? | Featrack',
  }
)
</script>

<template>
  <main class="bg-muted/50">
    <LandingHeader />

    <LandingHeroSection />

    <LandingCompareSection />
    <LandingHowToStartSection />
    <LandingFeaturesSection />
    <LandingPricingConfigurator :to-login="true" />
    <ClientOnly>
      <LandingGetStartedSection />
    </ClientOnly>
    <LandingFAQSection />
    <LandingFooter />
  </main>
</template>

<style scoped>
</style>
