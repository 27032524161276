<script lang="ts" setup>
const features: { title: string, icon: string, benefits: string[] }[] = [
  {
    title: 'Plans',
    icon: 'lucide:refresh-ccw-dot', // Replace with the actual icon class or path
    benefits: [
      'Group multiple features together under a single plan.',
      'Customize renewal cycles to fit your needs: daily, weekly, monthly, yearly, or even one-time.',
      'Seamlessly associate customers with their respective plans.',
      'Tailor plans to align with your existing billing and subscription models.'
    ]
  },
  {
    title: 'Customers',
    icon: 'lucide:users-round',
    benefits: [
      'Track individual customer usage in real-time.',
      'Easily assign customers to specific plans and features.',
      'Monitor which customers are nearing or exceeding their usage limits.',
      'Improve customer management with clear insights into consumption patterns.'
    ]
  },
  {
    title: 'Features',
    icon: 'lucide:wand-sparkles',
    benefits: [
      'Manage and track each feature individually for precise insights.',
      'Set different consumption rules per feature, adaptable to customer needs.',
      'Group features under different plans for more flexible offerings.',
      'See which features are most popular with customers for better product development decisions.'
    ]
  },
  {
    title: 'Limits and Warnings',
    icon: 'lucide:shield-alert',
    benefits: [
      'Set soft or hard limits for each plan to control feature access.',
      'Get notified when customers are close to reaching or exceeding limits.',
      'Configure warning thresholds to help prevent overuse and customer dissatisfaction.',
      'Offer upgrade opportunities when customers approach their limits.'
    ]
  },
  {
    title: 'Consumptions',
    icon: 'lucide:plug-zap',
    benefits: [
      'Track how customers are consuming specific features across all plans.',
      'Real-time monitoring of feature usage to prevent surprises.',
      'View consumption trends over time to optimize your service offerings.',
      'Easily analyze which customers are using the most resources.'
    ]
  },
  {
    title: 'Analytics',
    icon: 'lucide:chart-spline',
    benefits: [
      'Gain detailed insights into feature usage trends and customer behavior.',
      'View real-time and historical data to make informed decisions.',
      'Identify growth opportunities through upsell potential and usage patterns.',
      'Customize reports to focus on the metrics that matter most to your business.'
    ]
  }
]

const currentFeature = ref(0)
</script>

<template>
  <section id="features" class="pt-24 flex justify-center items-center bg-background">
    <div class="container">
      <h2 class="text-center text-4xl font-extrabold max-w-3xl mx-auto">
        Everything you need to monitor your <span class="font-black underline">users activity</span> and take the <span class="font-black underline">best actions</span>
      </h2>
      <p class="max-w-2xl mx-auto">
        Featrack offers a comprehensive set of features to help you track, manage, and optimize your product offerings. From plans and customers to features and analytics, we have you covered.
      </p>
      <div class="flex flex-wrap items-start justify-center gap-2 max-w-3xl mx-auto mt-16 mb-10">
        <div
          v-for="(feature, idx) in features" :key="idx" class="w-1/3 sm:w-1/4 md:w-1/5 lg:w-1/4 flex flex-col gap-y-6 transition-all items-center rounded-sm py-4 cursor-pointer "
          :class="{ 'bg-primary text-white': currentFeature === idx, 'text-primary hover:bg-primary/10': currentFeature !== idx }" @click="currentFeature = idx"
        >
          <div class="flex justify-center items-center w-full">
            <iconify-icon :icon="feature.icon" class="text-4xl" />
          </div>
          <div class="text-center">
            {{ feature.title }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-muted/40 py-24">
    <div class="mx-auto max-w-3xl container">
      <div class="flex flex-col gap-y-16">
        <div class="flex flex-col gap-y-8">
          <h3 class="text-2xl font-bold">
            {{ features[currentFeature].title }}
          </h3>
          <div class="flex flex-col gap-y-4">
            <div v-for="benefit in features[currentFeature].benefits" :key="benefit" class="flex items-center gap-x-2">
              <div class="size-6 bg-primary/30 flex place-items-center place-content-center rounded-sm border border-primary/20">
                <iconify-icon icon="lucide:check" class="text-primary" />
              </div>
              <span>{{ benefit }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
