<script lang="ts" setup>
const accordionItems = [
  {
    value: 'step-1',
    title: 'Create your plans, features and customers',
    content: 'Once you are registered and logged in, head to our dashboard in order to create your data.',
    details: [
      { title: 'Programmatically possible via our API (Soon)', icon: 'lucide:plug-zap' },
      { title: 'With configurable limits and alerts', icon: 'lucide:message-square-warning' },
    ]
  },
  {
    value: 'step-2',
    title: 'Send usage consumptions to Featrack',
    content: 'From your dashboard, head to settings to generate an API key. Send your customers usage consumptions via HTTP requests to our API.',
    details: [
      { title: 'In any language via our API', icon: 'lucide:square-code' },
      { title: 'Secured with encrypted api keys', icon: 'lucide:lock-keyhole' },
    ]
  },
  {
    value: 'step-3',
    title: 'Get insights and act on them',
    content: 'Use our dashboard to get insights and act on them. Set up alerts and limits to avoid costly surprises.',
    details: [
      { title: 'Consumptions available via our API', icon: 'lucide:download' },
      { title: 'Limits and blocks also accessible', icon: 'lucide:alert-triangle' },
    ]
  }
]

const step = ref('step-1')
</script>

<template>
  <section id="integration" class="min-h-[80vh] flex justify-center items-center bg-background">
    <div class="container">
      <h2 class="text-center text-4xl font-extrabold  max-w-3xl mx-auto">
        Get Started <span class="font-black underline">in Minutes</span>: Simple Steps to <span class="font-black underline">Track</span>, <span class="font-black underline">Monitor</span>, and <span class="font-black underline">Grow</span>
      </h2>

      <div class="flex w-full gap-x-16 gap-y-16 lg:gap-x-24 xl:gap-x-32 justify-center flex-wrap my-32">
        <div class="max-w-md">
          <Accordion v-model:model-value="step" type="single" class="w-full" collapsible>
            <AccordionItem v-for="item in accordionItems" :key="item.value" :value="item.value">
              <AccordionTrigger><span class="font-bold text-xl">{{ item.title }}</span></AccordionTrigger>
              <AccordionContent>
                <div>
                  <p>{{ item.content }}</p>
                  <div class="flex flex-col gap-y-2 mt-4">
                    <div v-for="detail in item.details" :key="detail.title" class="flex items-center gap-x-1">
                      <div class="size-5 flex place-items-center place-content-center">
                        <iconify-icon :icon="detail.icon" class="text-primary text-xs" />
                      </div>
                      <span class="font-bold text-xs">{{ detail.title }}</span>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
        <div class="w-2/5 hidden lg:block background-squares p-4 relative">
          <div class="absolute top-0 bottom-0 left-0 right-0 h-full w-full bg-[rgba(250,231,223,0.5)] dark:bg-[rgba(22,11,5,0.75)] " />

          <NuxtImg v-if="step === 'step-1'" loading="lazy" sizes="400px" width="400px" height="auto" alt="How data are created on Featrack.io" src="/landing/images/data_creation.png" class="rounded-md relative z-10" />
          <NuxtImg v-if="step === 'step-2'" loading="lazy" sizes="400px" width="400px" height="auto" alt="How realtime feature tracking works" src="/landing/images/data_sending.png" class="rounded-md relative z-10" />
          <NuxtImg v-if="step === 'step-3'" loading="lazy" sizes="400px" width="400px" height="auto" alt="Real time analytics and insights" src="/landing/images/data_analytics.png" class="rounded-md relative z-10" />
        </div>
      </div>
    </div>
  </section>
</template>
